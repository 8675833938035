<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('news-template-modal', {id: 0},shown,{width: '800px'})"
                        variant="primary"
                        class="mb-2 mr-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.news_template.label.id') }}</b-th>
                            <b-th>{{ $t('settings.news_template.label.name') }}</b-th>
                            <b-th>{{ $t('settings.news_template.label.subject') }}</b-th>
                            <b-th>{{ $t('settings.news_template.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="template in templates" :key="template.id">
                            <b-td>{{ template.id }}</b-td>
                            <b-td>{{ template.name }}</b-td>
                            <b-td>{{ template.subject }}</b-td>
                            <b-td>
                                <b-button
                                    @click="$root.$children[0].openModal('news-template-modal', {id: template.id},shown,{width: '800px'})"
                                    size="sm"
                                    variant="primary"
                                    class="mr-2"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>

                                <b-button @click="deleteTemplate(template.id)"
                                          size="sm"
                                          variant="danger"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Settings-news-templates',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            templates: [],
        }
    },
    methods: {
        ...mapGetters('NewsTemplate', ['getNewsTemplates']),
        shown() {
            const templatesFetch = this.$store.dispatch('NewsTemplate/fetchTemplates')

            Promise.all([templatesFetch])
                .then(() => {
                    this.templates = this.getNewsTemplates()
                })
                .finally(() => {
                    this.loading = false
                })
            this.$root.afterSettingsUpdate()
        },
        deleteTemplate(id) {
            this.$removeConfirm('NewsTemplate/deleteTemplate', id, this.shown)
        }
    },
    mounted() {
        this.shown()
    }
}
</script>